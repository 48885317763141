<template>
  <TosListVuetify :entityName="entityName" :headers="headers">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field  :label="tLabel('codice')" v-model="slotProps.data.codice" />
          </v-col>
          <v-col cols="4">
            <v-text-field   :label="tLabel('descrizione')" v-model="slotProps.data.descrizione" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-text-field  :label="tLabel('codice') + ' *'" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice"/>
            </v-col>
            <v-col cols="4">
              <v-text-field  :label="tLabel('descrizione') + ' *'" v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
            </v-col>
            <v-col cols="2">
              <v-checkbox :label="tLabel('convenzionale')" v-model="slotProps.data.convenzionale" :error-messages="slotProps.errors.convenzionale"/>
            </v-col>
            <v-col cols="2">
              <v-checkbox :label="tLabel('bloccato')" v-model="slotProps.data.bloccato" :error-messages="slotProps.errors.bloccato"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
              <v-text-field  :label="tLabel('num. assi')" v-model.number="slotProps.data.numeroAssi" :rules="[rules.numeric]" :error-messages="slotProps.errors.numeroAssi" />
            </v-col>
            <v-col cols="4">
              <v-text-field  :label="tLabel('portata per asse') + ' (kg)'" v-model.number="slotProps.data.portataPerAsse" :rules="[rules.numeric]" :error-messages="slotProps.errors.portataPerAsse" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('tara') + ' (kg) *'" v-model.number="slotProps.data.tara" :rules="[rules.required]" :error-messages="slotProps.errors.tara" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('portata_max') + ' (kg) *'" v-model.number="slotProps.data.portataMax" :rules="[rules.required]" :error-messages="slotProps.errors.portataMax" />
            </v-col>      
            <v-col cols="3">
              <v-text-field :label="tLabel('lunghezza') + ' (m) *'" v-model.number="slotProps.data.lunghezza" :rules="[rules.required]" :error-messages="slotProps.errors.lunghezza" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('lunghezza') + ' Max (TEU)'" v-model.number="slotProps.data.lunghezzaMaxTeu" />
            </v-col>                    
        </v-row>        
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "TipoCarri",
  data() {
    return {
      columns: null,
      entityName: "tipoCarri",

      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "codice" },
        { text: "Descrizione", value: "descrizione" },
        { text: "Num. assi", value: "numeroAssi" },
        { text: "Portata asse (kg)", value: "portataPerAsse" },
        { text: "Tara (kg)", value: "tara" },
        { text: "Lunghezza (m)", value: "lunghezza" },
        { text: "Lungh. Max (TEU)", value: "lunghezzaMaxTeu" },
        { text: "Portata max (kg)", value: "portataMax" },
        { text: "Convenzionale", value: "convenzionale", formatValue:'formatBoolean' },
        { text: "Bloccato", value: "bloccato", formatValue:'formatBoolean' }       
      ],
    };
  },
  created() {},
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
  methods: {
    validate(item) {
       let errors = {};
       const required = "codice,descrizione,tara,lunghezza,convenzionale,pesoMaxCarico";
       required.split(",").forEach(t => {
         const v = item[t];
         if (v == undefined || v == "") errors[t]="Campo obbligatorio";
       });
       return errors;
    }
  },
};
</script>
